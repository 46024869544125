import React, { useEffect, useState } from 'react'
import Homecultural from '../container/homeCulturalprgm/Homecultural'
import Programbanner2 from '../container/programBanner2/Programbanner2';
import ProgramDescription from '../container/programDescription/ProgramDescription';
import images from '../constants/images';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HashLink as Link1 } from "react-router-hash-link";
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../GlobalStateContext';

const data = [
    {
      id: 1,
      name: "Program Description",
    },
    {
      id: 2,
      name: "Benefits Of Our STEP",
    },
    {
      id: 3,
      name: "Fields",
    },
    {
      id: 4,
      name: "Why Choose the usintern.club?",
    },
    {
      id: 5,
      name: "Types of Host Businesses",
    },
    {
      id: 6,
      name: "Compensation",
    },
    {
      id: 7,
      name: "Fees",
    },
    {
      id: 8,
      name: "Eligibility",
    },
    {
      id: 9,
      name: "Application Process",
    },
  ];
const AllProgram4 = () => {
  const{t} = useTranslation();

    const benefits = [
        {
          id: 1,
          eligibility:
            "Participants get to practice their English skills",
        },
        {
          id: 2,
          eligibility:
            "Participants learn new business methods and structures",
        },
        {
          id: 3,
          eligibility:
            "Participants have the opportunity to travel in their free time",
        },
        {
          id: 4,
          eligibility: "Host families will explore their own neighborhood and local community with their participant",
        },
        {
          id: 5,
          eligibility:
            "International reference letter",
        },
        {
            id: 6,
            eligibility:
              "Certificate of Completion",
          },
          {
            id: 7,
            eligibility:
              "Networking opportunities with other STEP participants from 40+ different nationalities",
          },
      ];
      const fields_of_internshp = [
        { field: "General administration" },
        { field: "Social service" },
        { field: "Arts and culture" },
        { field: "Education" },
        { field: "Business fields: marketing, IT, sales, logistics, etc."},
      ];
      const types_of_business = [
        { field: "Not for profit organizations" },
        { field: "Senior centers" },
        { field: "Early education center" },
        { field: "Museums" },
        { field: "Libraries"},
        { field: "Community Centers"},
        { field: "Businesses"},
      ];
      const eligibility = [
        {
          id: 1,
          eligibility:
            "No degree or professional experience required",
        },
        {
          id: 2,
          eligibility:
            "Verifiable English Language skills: Lower intermediate or above",
        },
        {
          id: 3,
          eligibility:
            "Valid Passport",
        },
        {
          id: 4,
          eligibility: "B1/B2 visitor visa or ESTA for visa waiver countries",
        },
        {
          id: 5,
          eligibility:
            "Program duration is typically 1-3 months, extensions are possible",
        },
      ];
      const Application_process = [
        {
          process:
            "Register through our website free of charge by clicking here or by clicking the APPLY NOW button from the menu",
        },
        {
          process:
            "USAintern.club will contact you personally and arrange a video interview with you where we determine your eligibility and discuss our Program options with you",
        },
        {
          process:
            "If you are eligible and decide to participate in one of our great programs by accepting the Program Terms and Conditions, we will email you a detailed program description along with CV and Cover Letter templates",
        },
        {
          process:
            "We will put your file together from the documents acquired from you: your application survey, cover letter, CV, etc.",
        },
        {
          process:
            "Once we are ready preparing your documents, we will begin the placement process: we will choose all relevant open positions from our current database and send out your CV and Cover Letter to the chosen companies in the USA and keep you informed during the process.",
        },
        {
          process:
            "Once a match is found, we will prepare you for the interview with the company(s). We will schedule the interview for you.",
        },
        {
          process:
            "Once you are offered a position by one of the companies, we will give you a Pre-departure orientation and you are ready to buy your plane ticket! Have a great time in the USA!",
        },
      ];
    
      const location = useLocation();
      const cardDetails = location.state?.cardDetails || {};
      const navigate = useNavigate();
      const handleApply = () => {
        navigate("/contactus");
      };


    const [getindex, setIndex] = useState(0);
  // const handleLinkClick = (link, index) => {
  //   // setSelectedLink(link);
  //   setIndex(index);

  //   // Scroll to the corresponding section
  //   const element = document.getElementById(link);
  //   if (element) {
  //     let yOffset;
  //     if (window.matchMedia("(max-width: 403px)").matches) {
  //       yOffset = 390; // Offset for smaller screens
  //     }
  //     else if (window.matchMedia("(max-width: 414px)").matches) {
  //       yOffset = 340; // Offset for smaller screens
  //     }
  //     else if (window.matchMedia("(max-width: 569px)").matches) {
  //       yOffset = 300; // Offset for smaller screens
  //     }
  //     else if (window.matchMedia("(max-width: 783px)").matches) {
  //       yOffset = 230; // Offset for smaller screens
  //     } 
  //     else if (window.matchMedia("(max-width: 1276px)").matches) {
  //       yOffset = 180; // Offset for smaller screens
  //     } else {
  //       yOffset = 180; // Offset for larger screens
  //     }
  //     const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;
  //     window.scrollTo({ top: y, behavior: 'instant' });
  //   }
  // };
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'instant'
  //   });
  // }, []);
  const handleLinkClick = (link, index) => {
    setIndex(index);

    // Scroll to the corresponding section
    const element = document.getElementById(link);
    if (element) {
      // Get the height of the pro_nav_main_wrapp element
      const proNavHeight = document.querySelector('.pro_nav_main_wrapp').offsetHeight;

      // Define an additional offset of 92px for the main nav
      const additionalOffset = 82;

      // Calculate the scroll position with both offsets
      const y = element.getBoundingClientRect().top + window.pageYOffset - proNavHeight - additionalOffset;

      // Scroll to the calculated position
      window.scrollTo({
        top: y,
        behavior: 'instant' // Smooth scroll for a better experience
      });
    }
};

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.programdes_wrap');
      let currentIndex = 0;
      const scrollPosition = window.scrollY;

      const navbarHeight = 84.2; // Adjust the selector as per your actual class name
      const proNavBaseHeight = document.querySelector('.pro_nav_base_wrapp').offsetHeight;
    
      // Calculate the total offset
      const yOffset = navbarHeight + proNavBaseHeight;
  
      sections.forEach((section, index) => {
        const sectionTop = section.offsetTop - yOffset; // Adjusted offset for better UI
        const sectionBottom = sectionTop + section.offsetHeight;
  
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentIndex = index;
        }
      });
  
      setIndex(currentIndex);
    };
  
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { setScrollPosition } = useGlobalState();
  const handleApplyNowClick = () => {
    setScrollPosition(1);
  };

  const navigate1 = useNavigate();
    const handleCardClick1 = () => {
        // Navigate to the desired route
        navigate1('/InternshipUSA');
      };
      const handleCardClick2 = () => {
        // Navigate1 to the desired route
        navigate1('/ProfessionalCareerTrainingUSA');
      };
      const handleCardClick3 = () => {
        // Navigate1 to the desired route
        navigate1('/VirtualInternship');
      };
      const handleCardClick4 = () => {
        // Navigate1 to the desired route
        navigate1('/STEPUSA');
      };
      const handleCardClick5 = () => {
        // Navigate1 to the desired route
        navigate1('/AuPairProgram');
      };

  
  return (
    <div>
        <div>
            <div className="program2_main_wrapp4">
           {/* <img src={images.program_banner2} alt="" /> */}
                <div className='program-title'>
                    <p>{t("STEP Program")}</p>
                </div>
            </div>
        </div>
        <div className="pro_nav_main_wrapp">
        <div className="container">
          <div className="pro_nav_base_wrapp">
            <div className="pro_nav_links_step">
              {data && data.length > 0
                ? data.map((item, index) => {
                    return (
                      <button
                        className={getindex === index ? "selected-link" : ""}
                        onClick={() => handleLinkClick(item.id, index)}
                      >
                        {t(item.name)}
                      </button>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
      <div id='programdescription'>
      <div className="programdes-main">
      <div className="programdes_wrap" id="1">
        <p className="pro_des_title">{t("Program Description")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="pro_des_content">
          <p>{t("#STEP #USAvolunteer #gainexperience")}</p>
          <br />
          {/* <p>
            ARE YOU READY TO GET A LIFETIME EXPERIENCE AND STAND OUT FROM THE
            CROWD?
          </p>
          <br /> */}
          <p>
          {t("Short Term Enrichment Program (STEP) is a cultural immersion, and volunteering program that has been expanding all over the world, welcoming participants from all over the globe.")}
          </p>
          <br />
          <p>
          {t("Our participants spend 4, 8, or 12 weeks exploring and learning about American life. By living in a home-stay, they get to experience a typical American household.")}
          </p>
          <br/>
          <p>
          {t("During the day, participants volunteer and observe local non-profit organizations where they will experience the American spirit of donating their time to help a community in need.")}
          </p>
          <br />
          <p>
          {t("A B1/B2 or ESTA visa is required for this program, allowing students the chance to travel around America and visit our beautiful and historic sites, and to participate in events hosted by non-profit service organizations.")}
          </p>
          <br />
        </div>
      </div>
      <div className="programdes_wrap" id="2">
        <p className="pro_des_title">{t("BENEFITS OF OUR SHORT-TERM ENRICHMENT PROGRAM")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="eligibility-list">
          {benefits.map((list) => {
            return (
              <React.Fragment>
                <li className='eligibility-list-list1'>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {t(list.eligibility)}
                </li>
              </React.Fragment>
            );
          })}
        </div>
      </div>

      <div className="programdes_wrap" id="3">
        <p className="pro_des_title">{t("Fields")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="grid-container">
          {fields_of_internshp.map((items) => {
            return (
              <div className="grid-item">
                <img src={images.green_tick} alt="green-tick" />
                {t(items.field)}
              </div>
            );
          })}
        </div>
      </div>

      <div className="programdes_wrap" id="4">
        <p className="pro_des_title">{t("Why Choose the usintern.club?")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="fee-list">
        <ul>
            <li>
              {t("Expert guidance : Empowering your journey with our seasoned experts, every step of the way.")}
            </li>
            <li>
              {t("Transparent process : Clear and concise communication, because transparency matters.")}
            </li>
            <li>{t("Visa Preparation : Lean on our experts to guide you through the entire visa process, including interview coaching!")}</li>
            <li>{t("Cultural integration : Immersing you in the vibrant US culture, fostering personal growth and cultural emergence, as you live, learn, and thrive in America.")}</li>
            <li>{t("Continuous support : Support that doesn't end; from application to reaching the destination and beyond.")}</li>
            {t("Success stories: Proven successes with inspiring and motivating stories.") !== "Success stories: Proven successes with inspiring and motivating stories." && (
            <li>{t("Success stories: Proven successes with inspiring and motivating stories.")}</li> // Only render if it's available in the translation
          )}
          </ul>
        </div>
      </div>     

      <div className="programdes_wrap" id="5">
        <p className="pro_des_title">{t("Types of Host Businesses")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div class="grid-container">
          {types_of_business.map((items) => {
            return (
              <div class="grid-item">
                <img src={images.green_tick} alt="" />
                {t(items.field)}
              </div>
            );
          })}
        </div>
      </div>

      {/* Compensation */}
      <div className="programdes_wrap" id="6">
        <p className="pro_des_title">{t("Compensation")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="pro_des_content">
          <p>
          {t("Positions are unpaid.")}
          </p>
        </div>
      </div>
      {/* fees */}
      <div className="programdes_wrap" id="7">
        <p className="pro_des_title">{t("Fees")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="fee-list">
          <ul>
            <li>
            {t("Program consultation: FREE (Determine eligibility, Choose the right program etc.)")}
            </li>
            <li>
            {t("Program fee: $1000-$2000")}
            </li>
            <li>{t("Insurance: $65/month")}</li>
          </ul>
        </div>
      </div>
      {/* eligibility */}
      <div className="programdes_wrap" id="8">
        <p className="pro_des_title">{t("Eligibility")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="eligibility-list">
          {eligibility.map((list) => {
            return (
              <ul>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                  {t(list.eligibility)}
                </li>
              </ul>
            );
          })}
        </div>
      </div>


      {/* Application Process */}
      <div className="programdes_wrap" id="9">
        <p className="pro_des_title">{t("Application Process")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="application-list">
          {/* {Application_process.map((list) => {
            return ( */}
              <ul>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                  {/* {list.process} */}
                  <p>{t("Register through our website free of charge by clicking")} <Link 
                     to="/contactus" 
                     state={{scroll: 'true'}}
                     onClick={handleApplyNowClick}
                      // smooth 
                      // to="/#homegetintouch"
                      // scroll={(el) => {
                      //   const offset = 84.2; // Set your desired offset here
                      //   const elementPosition = el.getBoundingClientRect().top;
                      //   const offsetPosition = elementPosition - offset;
                      
                      //   window.scrollTo({
                      //     top: offsetPosition,
                      //     behavior: "instant",
                      //     // duration:100,
                      //   });
                      // }}  
                      className='app-link'>{t("here")}
                      </Link> {t("or by clicking the APPLY NOW button from the menu")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("USAintern.club will contact you personally and arrange a video interview with you where we determine your eligibility and discuss our Program options with you.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                  {/* {list.process} */}
                  <p>{t("If you are eligible and decide to participate in one of our great programs by accepting the Program Terms and Conditions, we will email you a detailed program description along with CV and Cover Letter templates.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("We will put your file together from the documents acquired from you: your application survey, cover letter, CV, etc.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("we are ready preparing your documents, we will begin the placement process: we will choose all relevant open positions from our current database and send out your CV and Cover Letter to the chosen companies in the USA and keep you informed during the process.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1"className='list-icon' />
                  {/* {list.process} */}
                  <p>{t("Once a match is found, we will prepare you for the interview with the company(s). We will schedule the interview for you.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("Once you are offered a position by one of the companies, we will give you a Pre-departure orientation and you are ready to buy your plane ticket! Have a great time in the USA!")}</p>
                </li>
              </ul>
            {/* );
          })} */}
        </div>
      </div>
      <div className="apply-btn-div">
      <Link
       to="/contactus" 
       state={{scroll: 'true'}}
       onClick={handleApplyNowClick}
        // smooth 
        // to="/#homegetintouch"
        // scroll={(el) => {
        //   const offset = 84.2; // Set your desired offset here
        //   const elementPosition = el.getBoundingClientRect().top;
        //   const offsetPosition = elementPosition - offset;
        
        //   window.scrollTo({
        //     top: offsetPosition,
        //     behavior: "instant",
        //     // duration:100,
        //   });
        // }} 
        className="apply-btn-new"
        >
        {t("apply now")}
        </Link>
      </div>
    </div>
    <div className='checkout_program'>
      <div className='home-section2_new'>
        <div className='home-sec2-content'>
          <div className='h-sec2-head'>
            <div ></div>
            <p className='h-checkout-text'>{t("Check Out Our Other Programs")}</p>
          </div>
          <div className='sec2-cards-div'>
            {/* internship usa card */}
            <div className='sec2-card-new' onClick={handleCardClick1}>
              <img src={images.sec2_card_img1} alt="" />
              <div className='sec2-card-del'>
              <p className='p-title'><span>{t('internship usa')}</span></p>
              <p className='p-des'>{t('J1 Visa Program for current...')}</p>
              <p className='full-details'>{t('J1 Visa Program for current university students or recent graduates. Get an internship in your own field!')}</p> 
              </div>
            </div>

            {/* Professional career training USA card */}
            <div className='sec2-card-new' onClick={handleCardClick2}>
              <img src={images.sec2_card_img2} alt="" />
              <div className='sec2-card-del1'>
                <p className='p-title'> 
                  <span>
                  {t('professional career training usa')}
                   </span>
                </p>
                <p className='p-des'>{t('J1 Visa Program for young...')}</p>
                <p className='full-details'>{t('J1 Visa Program for young professionals with a diploma in higher education. Get a position is your own career field!')}</p>  
              </div>    
            </div>

            {/* AU Pair usa card */}
            <div className='sec2-card-new' onClick={handleCardClick5}>
              <img src={images.sec2_card_img5} alt="" />
              <div className='sec2-card-del'>
                <p className='p-title'><span>{t("au pair usa")}</span></p>
                <p className='p-des'>{t('J1 Visa Program for young...')}</p>
                <p className='full-details'>{t('J1 Visa Program for young caregivers between the ages of 18-26. Join Us for an opportunity to grow, learn, and create lifelong bonds across borders!')}</p> 
              </div>
            </div>

            {/* Virtual Internship card */}
            <div className='sec2-card-new' onClick={handleCardClick3}>
              <img src={images.sec2_card_img3}alt="" />
              <div className='sec2-card-del'>
                <p className='p-title'>
                  <span>{t("virtual internship")}</span></p>
                  <p className='p-des'>{t('Project-based virtual position...')}</p>
                  <p className='full-details'>{t('Project-based virtual position guided by a mentor within an American Organization')}</p>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>
    </div>
      
    </div>
  )
}

export default AllProgram4