import React, { useEffect, useState } from 'react'
import Homecultural from '../container/homeCulturalprgm/Homecultural'
import Programbanner2 from '../container/programBanner2/Programbanner2';
import ProgramDescription from '../container/programDescription/ProgramDescription';
import images from '../constants/images';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HashLink as Link1 } from "react-router-hash-link";
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../GlobalStateContext';

const data = [
    {
      id: 1,
      name: "Program Description",
    },
    {
      id: 2,
      name: "Eligibility",
    },
    {
      id: 3,
      name: "Program Options",
    },
    {
        id: 4,
        name: "Duties",
      },
    {
      id: 5,
      name: "Compensation",
    },
    {
      id: 6,
      name: "Housing",
    },
    {
      id: 7,
      name: "Fees",
    },
    {
      id: 8,
      name: "Application Process",
    },
  ];
const AuPairProgram = () => {

  const{t} = useTranslation();

    const eligibility = [
        {
          id: 1,
          eligibility:
            "Hold a high school diploma or equivalent",
        },
        {
          id: 2,
          eligibility:
            "Be between the ages of 18 and 26",
        },
        {
          id: 3,
          eligibility:
            "Have a valid passport",
        },
        {
          id: 4,
          eligibility: "Proof of 200 hours of childcare experience",
        },
        {
          id: 5,
          eligibility:
            "Have a clean criminal record",
        },
        {
            id: 6,
            eligibility:
              "Pass the interview conducted with our agency (checking English level and eligibility)",
        },
        {
            id: 7,
            eligibility:
              "Be proficient in English",
        },
        {
            id: 8,
            eligibility:
              "Good Health",
        },
      ];
     
    
      const location = useLocation();
      const cardDetails = location.state?.cardDetails || {};
      const navigate = useNavigate();
      const handleApply = () => {
        navigate("/contactus");
      };


    const [getindex, setIndex] = useState(0);
  // const handleLinkClick = (link, index) => {
  //   // setSelectedLink(link);
  //   setIndex(index);

  //   // Scroll to the corresponding section
  //   const element = document.getElementById(link);
  //   if (element) {
  //     let yOffset;
  //     if (window.matchMedia("(max-width: 337px)").matches) {
  //       yOffset = 340; // Offset for smaller screens
  //     }
  //     else if (window.matchMedia("(max-width: 448px)").matches) {
  //       yOffset = 290; // Offset for smaller screens
  //     }
  //     else if (window.matchMedia("(max-width: 609px)").matches) {
  //       yOffset = 230; // Offset for smaller screens
  //     } 
  //     else if (window.matchMedia("(max-width: 786px)").matches) {
  //       yOffset = 190; // Offset for smaller screens
  //     } else {
  //       yOffset = 180; // Offset for larger screens
  //     }
  //     const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;
  //     window.scrollTo({ top: y, behavior: 'instant' });
  //   }
  // };
  const handleLinkClick = (link, index) => {
    setIndex(index);

    // Scroll to the corresponding section
    const element = document.getElementById(link);
    if (element) {
      // Get the height of the pro_nav_main_wrapp element
      const proNavHeight = document.querySelector('.pro_nav_main_wrapp').offsetHeight;

      // Define an additional offset of 92px for the main nav
      const additionalOffset = 82;

      // Calculate the scroll position with both offsets
      const y = element.getBoundingClientRect().top + window.pageYOffset - proNavHeight - additionalOffset;

      // Scroll to the calculated position
      window.scrollTo({
        top: y,
        behavior: 'instant' // Smooth scroll for a better experience
      });
    }
};
  useEffect(() => {
    window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.programdes_wrap');
      let currentIndex = 0;
      const scrollPosition = window.scrollY;

      const navbarHeight = 84.2; // Adjust the selector as per your actual class name
      const proNavBaseHeight = document.querySelector('.pro_nav_base_wrapp').offsetHeight;
    
      // Calculate the total offset
      const yOffset = navbarHeight + proNavBaseHeight;
  
      sections.forEach((section, index) => {
        const sectionTop = section.offsetTop - yOffset; // Adjusted offset for better UI
        const sectionBottom = sectionTop + section.offsetHeight;
  
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentIndex = index;
        }
      });
  
      setIndex(currentIndex);
    };
  
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigate1 = useNavigate();
  const handleCardClick1 = () => {
      // Navigate to the desired route
      navigate1('/InternshipUSA');
    };
    const handleCardClick2 = () => {
      // Navigate1 to the desired route
      navigate1('/ProfessionalCareerTrainingUSA');
    };
    const handleCardClick3 = () => {
      // Navigate1 to the desired route
      navigate1('/VirtualInternship');
    };
    const handleCardClick4 = () => {
      // Navigate1 to the desired route
      navigate1('/STEPUSA');
    };
    const handleCardClick5 = () => {
      // Navigate1 to the desired route
      navigate1('/AuPairProgram');
    };


    const { setScrollPosition } = useGlobalState();
    const handleApplyNowClick = () => {
      setScrollPosition(1);
    };
  
  return (
    <div>
        <div>
            <div className="program2_main_wrapp5">
           {/* <img src={images.program_banner2} alt="" /> */}
                <div className='program-title'>
                    <p>AU-PAIR USA</p>
                </div>
            </div>
        </div>
        <div className="pro_nav_main_wrapp">
        <div className="container">
          <div className="pro_nav_base_wrapp">
            <div className="pro_nav_links_au">
              {data && data.length > 0
                ? data.map((item, index) => {
                    return (
                      <button
                        className={getindex === index ? "selected-link" : ""}
                        onClick={() => handleLinkClick(item.id, index)}
                      >
                        {t(item.name)}
                      </button>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
      <div id='programdescription'>
      <div className="programdes-main">
      <div className="programdes_wrap" id="1">
        <p className="pro_des_title">{t("Program Description")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="pro_des_content">
          <p>{t("Join our Au-Pair program for adventure, fun, and an enriching cultural experience!")}</p>
          <br />
          <p>{t("Care for children, explore the USA, and create unforgettable memories with a welcoming host family.")}</p>
          <br />
          <p>{t("Experience the joy and wonder of seeing the world through a child’s eyes, and the opportunity to immerse yourself in American culture.")}</p>
          <p>{t("Embark on your journey with us today!")}</p>
          <br/>
          <div  className='pro-des-sub-div-au'>
            <p className='pro-des-sub-title'>{t("WHY BECOME AN AU-PAIR?")}</p>
            {/* <br/> */}
            <ul>
                <li>
                    1.   &nbsp;&nbsp;{t("Travel abroad & Cultural immersion")}
                    <p className='pro-des-sub-del'>
                        <li className='au-list'>{t("Explore vibrant cities and iconic landmarks during your time off.")}
                        </li>
                        <li className='au-list'>
                        {t("Immerse yourself in American culture within the cozy confines of a host family’s home, all while sharing stories of your own culture. This cultural exchange program is an excellent way to broaden your worldview!")}
                        </li>
                    </p>
                </li>
                <li>
                    2.   &nbsp;&nbsp;{t("Language skills")}
                    <p className='pro-des-sub-del'>{t("Improve your English language skills easily, by living and communicating with locals.")}
                    </p>
                </li>
                <li>
                    3.   &nbsp;&nbsp;{t("Networking & CV boost")}
                    <p className='pro-des-sub-del'>
                    {t("Create professional connections and enhance your Resume with international experience, language skills, and childcare expertise.")}
                    </p>
                </li>
                <li>
                    4.   &nbsp;&nbsp;{t("International friendships")}
                    <p className='pro-des-sub-del'>
                    {t("Build lifelong friendships with other au pairs from around the world.")}
                    </p>
                </li>
                <li>
                    5.   &nbsp;&nbsp;{t("Personal growth")}
                    <p className='pro-des-sub-del'>
                    {t("Develop independence, adaptability, and problem-solving skills in a new environment.")}
                    </p>
                </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="programdes_wrap" id="2">
        <p className="pro_des_title">{t("Eligibility")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <p className='pro_des_content_p'>{t("To qualify for our Au-Pair program, applicants must:")}</p><br/>
        <div className="eligibility-list">
          {eligibility.map((list) => {
            return (
              <React.Fragment>
                <li className='eligibility-list-list1'>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                  {t(list.eligibility)}
                </li>
              </React.Fragment>
            );
          })}
        </div>
        <div className='pro-des-sub-div-au'> 
        <p className='pro-des-sub-title'>{t("Desired Applicant Characteristic and Experience:")} </p><br/>
        <div  className='fee-list'>
            <ul>
                <li>
                {t("Infant care experience (children under the age of 2 – at least 200 verified hours of infant care experience)")} 
                </li>
                <li>
                {t("Very positive childcare / infant care references")} 
                </li>
                <li>
                {t("Valid driver’s license with excellent driving record")} 
                </li>
                <li>
                {t("Additional skills such as swimming, sports, cooking, music or greater knowledge of academics")} 
                </li>
                <li>
                {t("An ‘open-minded’ attitude towards religion, race, and single parent families")} 
                </li>
                <li>
                {t("Willingness to care for more than two children")} 
                </li>
                <li>
                {t("Formal childcare, child development or first aid training")} 
                </li>
                <li>
                {t("Teaching, nursing, or camp counseling experience")} 
                </li>
                <li>
                {t("Experience living away from home")} 
                </li>
                <li>
                {t("Extensive childcare experience (more than 500 verified hours)")}
                </li>
            </ul>
            </div>
          </div>
      </div>

      <div className="programdes_wrap" id="3">
        <p className="pro_des_title">{t("Program Options")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="pro_des_content">
          <p>
            <span className="underlinepro">{t("Independent Program")}</span>{t("If you already found a host family in the USA on your own, we will help you with the visa process.")}
          </p>
          <p>
            <span className="underlinepro">{t("Full Program")}</span>{t("We will find a host family in the USA for you and also help you with the visa process.")}
          </p>
        </div>
      </div>

       {/* Duties */}
       <div className="programdes_wrap" id="4">
        <p className="pro_des_title">{t("Duties")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="fee-list">
            <ul>
                <li>
                {t("work up to 45 hours per week (flexible schedule and no more than 10 hours per day)")}
                </li>
                <li>
                {t("assist with light housekeeping (related to children e.g. cooking, cleaning, laundry)")} 
                </li>
                <li>
                {t("drive children to or from school appointments & activities")}
                </li>
                <li>
                {t("introduce language and culture of home country to the host family")}
                </li>
            </ul>
          {/* <p>
          
          </p>
          <p></p>
          <p> </p>
          <p></p> */}
        </div>
      </div>

      {/* Compensation */}
      <div className="programdes_wrap" id="5">
        <p className="pro_des_title">{t("Compensation")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <p className='pro_des_content_p'>{t("As an Au-Pair, you will receive:")}</p><br/>
        <div className="eligibility-list">
            <ul>
                <li>
                    <img src={images.list_icon} alt="Icon 1" className='list-icon' />{t("A direct weekly payment from the host family")}
                </li>
                <li>
                    <img src={images.list_icon} alt="Icon 1" className='list-icon' />{t("Two weeks paid vacation; one full weekend off per month")}
                </li>
                <li>
                <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                {t("$500 educational allowance")} 
                </li>
                <li>
                <img src={images.list_icon} alt="Icon 1" className='list-icon' />{t("Private room and full board")}
                </li>
                <li>
                <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                {t("Medical insurance")}  
                </li>
                <li>
                <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                {t("Paid round trip flight to the host family’s community")}   
                </li>
                <li>
                <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                {t("Comprehensive on-line orientation training prior to arrival")}   
                </li>
                <li>
                <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                {t("Regularly scheduled social and cultural activities with other area au pairs")}
                </li>
                <li>
                <img src={images.list_icon} alt="Icon 1" className='list-icon' />
                {t("24-hour support")}
                </li>
            </ul>
          
        </div>
      </div>

      {/* Housing */}
      <div className="programdes_wrap" id="6">
        <p className="pro_des_title">{t("Housing")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="pro_des_content">
          <p>
          {t("During your stay, you'll reside with your host family, providing you with an authentic American living experience. You'll have your own room and access to shared facilities within the home.")}
          </p>
        </div>
      </div>

      {/* Fees */}
      <div className="programdes_wrap" id="7">
        <p className="pro_des_title">{t("Fees")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="fee-list">
          <ul>
            <li>
            {t("Program consultation: FREE (Determine eligibility, Choose the right program etc.)")}
            </li>
            <li>
            {t("Program fee $1000")}
            </li>
            <li>{t("US Consulate Visa fee – $185 (paid to the embassy)")}</li>
            <li>{t("Insurance – Free (paid by the host family)")}</li>
          </ul>
        </div>
      </div>

      {/* Application Process */}
      <div className="programdes_wrap" id="8">
        <p className="pro_des_title">{t("Application Process")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="application-list">
          {/* {Application_process.map((list) => {
            return ( */}
              <ul>
                <li>
                  <img src={images.list_icon} alt="Icon 1"className='list-icon' />
                  {/* {list.process} */}
                  <p>{t("Register through our website free of charge by clicking")} <Link 
                      to="/contactus" 
                      state={{scroll: 'true'}}
                      onClick={handleApplyNowClick}
                      // smooth 
                      // to="/#homegetintouch"
                      // scroll={(el) => {
                      //   const offset = 84.2; // Set your desired offset here
                      //   const elementPosition = el.getBoundingClientRect().top;
                      //   const offsetPosition = elementPosition - offset;
                      
                      //   window.scrollTo({
                      //     top: offsetPosition,
                      //     behavior: "instant",
                      //     // duration:100,
                      //   });
                      // }}  
                      className='app-link'>{t("here")}
                      </Link> {t("or by clicking the APPLY NOW button from the menu")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1"className='list-icon' />
                  {/* {list.process} */}
                  <p>{t("USAintern.club will contact you personally and arrange a video interview with you where we determine your eligibility and discuss our Program options with you.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("If you are eligible and decide to participate in one of our great programs by accepting the Program Terms and Conditions, we will email you a detailed program description along with CV and Cover Letter templates.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("We will put your file together from the documents acquired from you: your application survey, CV, letter of introduction to the host family, Video application, etc.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("Once we are ready preparing your documents, we will begin the placement process. Your online application will be available for our host families to view.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("Once a match is found, we will prepare you for the interview with the host family.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1"className='list-icon' />
                  {/* {list.process} */}
                  <p>{t("Once you are offered an au-pair position by one of our host families, we will start the J1 Visa Process.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1"className='list-icon' />
                  {/* {list.process} */}
                  <p>{t("The Visa Sponsor will issue the necessary paperwork for the J1 Visa.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1"className='list-icon' />
                  {/* {list.process} */}
                  <p>{t("When you have the necessary documents ready, you can schedule a visa interview with the U.S. Embassy. You will need to pay the visa fee to the Embassy directly..")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("After you pass the Visa Interview and your Visa is issued, a pre-travel orientation will take place to prepare you for the best year in your life!")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("The host family will pay for your round-trip flight. Pack your bags and get ready for an unforgettable experience as an Au-Pair in the USA")}</p>
                </li>
              </ul>
            {/* );
          })} */}
        </div>
      </div>
      <div className="apply-btn-div">
      <Link 
         to="/contactus" 
         state={{scroll: 'true'}}
         onClick={handleApplyNowClick}
        // smooth 
        // to="/#homegetintouch"
        // scroll={(el) => {
        //   const offset = 84.2; // Set your desired offset here
        //   const elementPosition = el.getBoundingClientRect().top;
        //   const offsetPosition = elementPosition - offset;
        
        //   window.scrollTo({
        //     top: offsetPosition,
        //     behavior: "instant",
        //     // duration:100,
        //   });
        // }} 
        className="apply-btn-new"
        >
        {t("apply now")}
        </Link>
      </div>
    </div>
    <div className='checkout_program'>
      <div className='home-section2_new'>
        <div className='home-sec2-content'>
          <div className='h-sec2-head'>
            <div ></div>
            <p className='h-checkout-text'>{t("Check Out Our Other Programs")}</p>
          </div>
          <div className='sec2-cards-div'>
            {/* internship usa card */}
            <div className='sec2-card-new' onClick={handleCardClick1}>
              <img src={images.sec2_card_img1} alt="" />
              <div className='sec2-card-del'>
                <p className='p-title'><span>{t('internship usa')}</span></p>
                <p className='p-des'>{t('J1 Visa Program for current...')}</p>
                <p className='full-details'>{t('J1 Visa Program for current university students or recent graduates. Get an internship in your own field!')}</p> 
              </div>
            </div>

            {/* Professional career training USA card */}
            <div className='sec2-card-new' onClick={handleCardClick2}>
              <img src={images.sec2_card_img2} alt="" />
              <div className='sec2-card-del1'>
                <p className='p-title'> 
                  <span>
                  {t('professional career training usa')}
                  </span>
                </p>
                <p className='p-des'>{t('J1 Visa Program for young...')}</p>
                <p className='full-details'>{t('J1 Visa Program for young professionals with a diploma in higher education. Get a position is your own career field!')}</p> 
              </div>    
            </div>

            {/* Virtual Internship card */}
            <div className='sec2-card-new' onClick={handleCardClick3}>
              <img src={images.sec2_card_img3}alt="" />
              <div className='sec2-card-del'>
                <p className='p-title'>
                  <span>{t("virtual internship")}</span></p>
                  <p className='p-des'>{t('Project-based virtual position...')}</p>
                  <p className='full-details'>{t('Project-based virtual position guided by a mentor within an American Organization')}</p> 
              </div>
            </div>

            {/*  STEP USA */}
            <div className='sec2-card-new' onClick={handleCardClick4}>
              <img src={images.sec2_card_img4} alt="" />
              <div  className='sec2-card-del'>
                <p className='p-title'>
                  <span>
                  {t("step usa")}
                  </span></p>
                <p className='p-des'>{t('Short Term Enrichment Program...')}</p>
                <p className='full-details'>{t('Short Term Enrichment Program (STEP) is a cultural immersion and volunteering program with a tourist visa or ESTA')}</p> 
              </div>
                        
            </div>
          </div>
        </div> 
      </div>
    </div>
    </div>
      
    </div>
  )
}

export default AuPairProgram